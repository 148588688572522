import { Component, OnInit, Input } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @Input() items = [];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toDetail(id) {
    this.router.navigateByUrl('/news/detail?id=' + id);
  }
}
