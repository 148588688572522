<app-header [currentSection]="'news'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h1 class="home-title">NEWS</h1>
          <h4>Focus on the industry news, hotspots, and latest industry innovation information.</h4>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png');"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png');"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png');"></div>
      </div>
    </div>
  </section>
</div>

<section class="section bg-light" style="min-height: 500px; padding-bottom: 30px;">
  <div class="container">
    <div class="row">
      <ngb-tabset style="width: 100%;">
        <ng-container *ngFor="let category of categories">
          <ngb-tab *ngIf="category.list.length > 0">
            <ng-template ngbTabTitle>
              {{ category.name }}
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row margin-t-30">
                <div class="col-lg-3 col-md-6" *ngFor="let news of category.list">
                  <div class="blog-box margin-t-30 text-center" (click)="toDetail(news.id)">
                    <img src="{{
                        news.thumb ? news.thumb : 'assets/images/xw.png'
                      }}" class="img-fluid rounded" alt="" />
                    <div>
                      <h5 class="mt-4 text-muted">
                        {{ news.created_at | date: "yyyy-MM-dd HH:mm" }}
                      </h5>
                      <h4 class="mt-3">
                        <a href="javascript: void(0);" class="blog-title">
                          {{ news.title }}
                        </a>
                      </h4>
                      <p class="text-muted short">
                        {{ news.short_description }}
                      </p>
                      <div class="mt-3">
                        <a href="javascript: void(0);" class="read-btn">MORE <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ng-container>
      </ngb-tabset>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<app-footer></app-footer>
