<app-header [currentSection]="'application-field'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h1 class="home-title">{{ subTitle }}</h1>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
      </div>
    </div>
  </section>
</div>

<section class="section bg-light" style="min-height: 500px; padding-bottom: 30px">
  <div class="container">
    <div class="row">
      <ngb-accordion style="min-width: 100%" #acc="ngbAccordion">
        <ngb-panel *ngFor="let category of categories" [id]="category.id">
          <ng-template ngbPanelTitle>
            <span>{{ category.name }}</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <ng-container *ngIf="category.child_nodes.length > 0">
              <ngb-tabset style="width: 100%">
                <ng-container *ngFor="let childrenCategory of category.child_nodes">
                  <ngb-tab>
                    <ng-template ngbTabTitle>
                      {{ childrenCategory.name }}
                    </ng-template>
                    <ng-template ngbTabContent>
                      <div class="row">
                        <div class="col-lg-3 col-md-6" *ngFor="let product of childrenCategory.products">
                          <div class="blog-box margin-t-30 add-cursor" style="text-align: center"
                            (click)="toDetail(product)">
                            <div style="width: 100%; text-align: center" [ngClass]="
                                !product.loaded ? 'img-placeholder' : ''
                              ">
                              <ngx-picture src="{{ product.thumb }}" class="img-fluid rounded" [lazyLoad]="true"
                                (loaded)="imgLoaded($event, product)"></ngx-picture>
                            </div>
                            <div>
                              <h4 class="mt-3">
                                <a href="javascript: void(0);" class="blog-title">
                                  {{ product.name }}
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-tab>
                </ng-container>
              </ngb-tabset>
            </ng-container>
            <ng-container *ngIf="category.child_nodes.length == 0">
              <div class="row">
                <div class="col-lg-3 col-md-6" *ngFor="let product of category.products">
                  <div class="blog-box margin-t-30" style="text-align: center" (click)="toDetail(product)">
                    <div style="width: 100%; text-align: center" [ngClass]="!product.loaded ? 'img-placeholder' : ''">
                      <ngx-picture src="{{ product.thumb }}" class="img-fluid rounded" [lazyLoad]="true"
                        (loaded)="imgLoaded($event, product)"></ngx-picture>
                    </div>
                    <div>
                      <h4 class="mt-3">
                        <a href="javascript: void(0);" class="blog-title">
                          {{ product.name }}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<app-footer></app-footer>
