<!-- START OLD PRODUCT -->
<section class="section bg-light" id="product-old" *ngIf="false">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">PRODUCTS</h1>
        <div class="section-title-border margin-t-20"></div>
        <div class="mt-3 text-center">
          <!--<a href="/product/list" class="read-btn">更多产品</a>-->
        </div>
        <p class="section-subtitle text-muted text-center font-secondary padding-t-30">
          Supplier of three series of water treatment filteration media, water treatment chemicals, and water treatment
          stuffing media, with hundreds of products, they meet the needs of different industries.
        </p>
      </div>
    </div>
    <div class="row margin-t-30">
      <div class="col-lg-4">
        <div class="blog-box margin-t-30 text-center" (click)="toList('sclyj')">
          <img src="assets/images/product/yj.jpg" class="img-fluid rounded" style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('sclyj')" class="blog-title" style="float: left">Chemicals</a>
              <a (click)="toList('sclyj')" class="read-btn" style="float: right"> detail<i
                  class="mdi mdi-arrow-right"></i></a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="blog-box margin-t-30 text-center" (click)="toList('sclll')">
          <img src="assets/images/product/ll.jpg" class="img-fluid rounded" style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('sclll')" class="blog-title" style="float: left"> Filteration Media </a>
              <a (click)="toList('sclll')" class="read-btn" style="float: right"> detail<i
                  class="mdi mdi-arrow-right"></i></a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="blog-box margin-t-30 text-center" (click)="toList('scltl')">
          <img src="assets/images/product/tl.jpg" class="img-fluid rounded" style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('scltl')" class="blog-title" style="float: left">Stuffing Media</a>
              <a (click)="toList('scltl')" class="read-btn" style="float: right"> detail<i
                  class="mdi mdi-arrow-right"></i></a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END OLD PRODUCT -->

<!-- START PRODUCT -->
<section class="section bg-light" id="product">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">High-Quality Products</h1>
        <div class="mt-3 text-center">
          <!--<a href="/product/list" class="read-btn">更多产品</a>-->
        </div>
        <p class="section-subtitle text-muted text-center font-secondary">YB Water Treatment Chemical Products Offer:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_1')">
          <img src="assets/images/product/yjlzghcj.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_1')" class="blog-title text-center"> Organic Phosphonic Acid Corrosion Inhibitor </a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_2')">
          <img src="assets/images/product/jsszgfsj.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_2')" class="blog-title text-center">Polycarboxylate-based Scale Inhibitor and Dispersant</a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_3')">
          <img src="assets/images/product/sjmzj.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_3')" class="blog-title text-center"> Biocides and Disinfectants </a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_4')">
          <img src="assets/images/product/fhxzghcj.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_4')" class="blog-title text-center">Compounded Scale Inhibitor</a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_5')">
          <img src="assets/images/product/fstzgj.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_5')" class="blog-title text-center">Reverse Osmosis</a>
            </h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="blog-box margin-t-30 text-center" (click)="toList('category_6')">
          <img src="assets/images/product/xnj_ll.jpg" class="img-fluid rounded"  style="max-width: 100%;">
          <div>
            <h4 class="mt-3">
              <a (click)="toList('category_6')" class="blog-title text-center"> Filtration Media and Defoamers </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- END PRODUCT -->

