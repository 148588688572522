import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.css']
})
export class NewsListComponent {
    categories = [];

    constructor(private router: Router,
        private http: HttpClient,
        private spinner: NgxSpinnerService) {
        this.readNewsList();
    }

    toDetail(id) {
        this.router.navigateByUrl('/news/detail?id=' + id);
    }

    readNewsList() {
        this.spinner.show();
        this.http.post('/news/list', {})
            .pipe(finalize(() => {
                setTimeout(() => {
                    this.spinner.hide();
                }, 618);
            }))
            .subscribe((res: any) => {
                this.categories = res.categories;
            });
    }
}
