import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-product-list-by-category',
  templateUrl: './product-list-by-category.component.html',
  styleUrls: ['./product-list-by-category.component.css']
})
export class ProductListByCategoryComponent  implements OnInit {
  category = null;
  category_id = null;
  current_category = null;
  childrenCategories = [];
  tags = [];
  products = [];
  subTitle = '';
  categories = [
    {
      display: 'Organic Phosphonic Acid Corrosion Inhibitor',
      category: 'category_1',
      thumb: 'assets/images/product/yjlzghcj.jpg'
    },
    {
      display: 'Polycarboxylate-based Scale Inhibitor and Dispersant',
      category: 'category_2',
      thumb: 'assets/images/product/jsszgfsj.jpg'
    },
    {
      display: 'Biocides and Disinfectants',
      category: 'category_3',
      thumb: 'assets/images/product/sjmzj.jpg'
    },
    {
      display: 'Compounded Scale Inhibitor',
      category: 'category_4',
      thumb: 'assets/images/product/fhxzghcj.jpg'
    },
    {
      display: 'Reverse Osmosis',
      category: 'category_5',
      thumb: 'assets/images/product/fstzgj.jpg'
    },
    {
      display: 'Filtration Media and Defoamers',
      category: 'category_6',
      thumb: 'assets/images/product/xnj_ll.jpg'
    }
  ];

  constructor(private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    private spinner: NgxSpinnerService) {
    this.scrollToService.scrollTo({ target: 'home' });
    this.route.queryParams.subscribe((params) => {
      this.category_id = params.category ? params.category : null;
      if (this.category_id) {
        this.current_category = null;
        this.categories.forEach((item) => {
          if (this.category_id == item.category) {
            this.current_category = item;
          }
        })
        this.readProductList();
      }
    })
  }

  ngOnInit() {
  }

  toDetail(product) {
    this.router.navigateByUrl('/product/detail?id=' + product.id);
  }

  readProductList() {
    this.spinner.show();
    forkJoin(this.http.post('/product/tags', {}), this.http.post('/product/list-by-category', { category: this.category_id }))
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 618);
      }))
      .subscribe((res: any) => {
        this.category = res[1].category;
        this.products = res[1].products.map((product) => {
          product.tagList = product.tags.map((tag) => {
            return tag.name.en;
          });
          return product;
        });
        this.childrenCategories = res[1].childrenCategories;
        this.subTitle = res[1].subTitle;
        this.childrenCategories = res[1].childrenCategories.map((childCategory) => {
          childCategory.products = this.products.filter((product) => {
            return product.category_id == childCategory.id
          });
          return childCategory;
        });
        this.tags = res[0].tags.map((tag) => {
          tag.products = this.products.filter((product) => {
            return product.tagList.indexOf(tag.name.en) >= 0
          });
          tag.childrenCategories = res[1].childrenCategories.map((childrenCategory) => {
            childrenCategory.products = this.products.filter((product) => {
              return product.tagList.indexOf(tag.name.en) >= 0 && product.category_id == childrenCategory.id
            });
            return childrenCategory;
          }).filter((childrenCategory) => {
            return childrenCategory.products.length > 0;
          });
          return tag;
        });
      });
  }

  imgLoaded($event, product) {
    product.loaded = true;
  }

  toList(category) {
    if (category) {
      this.current_category = category;
      this.category_id = category.category;
      this.readProductList();
    } else {
      this.current_category = null;
      this.category_id = null;
    }
  }
}
