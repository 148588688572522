<app-header [currentSection]="'product'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h1 class="home-title" *ngIf="product">{{ product.name }}</h1>
          <h4 class="add-cursor" (click)="back()">back</h4>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png');"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png');"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png');"></div>
      </div>
    </div>
  </section>
</div>

<section class="section bg-light" style="padding-top: 0px; min-height: 500px; padding-bottom: 30px;">
  <div class="container">
    <div class="row" *ngIf="product">
      <div class="col-lg-8 offset-lg-2" style="text-align: center; margin-top: 30px;" *ngIf="product.thumb">
        <img [src]="product.thumb" style="max-width: 300px;">
      </div>
      <div class="col-lg-8 offset-lg-2">
        <div [innerHTML]="product.description | safeHtml" *ngIf="product"></div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<app-footer></app-footer>
