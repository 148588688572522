<!-- START NEWS -->
<section class="section bg-light" id="news">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">NEWS</h1>
        <div class="section-title-border margin-t-20"></div>
        <div class="mt-3 text-center">
          <a href="/news/list" class="read-btn">MORE</a>
        </div>
        <p class="section-subtitle text-muted text-center font-secondary padding-t-30">
          Develop environmental protection industry. Strengthening the production of water treatment chemicals has
          extraordinary significance for water conservation and sewage treatment. There are many types of water
          treatment chemicals, the varieties are updated quickly, the technical service requirements are quite high, and
          various products have strong professionalism.
        </p>
      </div>
    </div>

    <div class="row margin-t-30">
      <div class="col-lg-3" *ngFor="let item of items;">
        <div class="blog-box margin-t-30" (click)="toDetail(item.id)">
          <div class="news-title text-center">
            <img src="assets/images/xw.png" class="img-fluid rounded" alt="" *ngIf="!item.thumb">
            <img src="{{item.thumb}}" class="img-fluid rounded" alt="" *ngIf="item.thumb">
            <h5 class="mt-4 text-muted">{{item.created_at | date: 'yyyy-MM-dd HH:mm'}}</h5>
            <h4 class="mt-3">
              <a href="javascript: void(0);" class="blog-title">
                {{item.title}}
              </a>
            </h4>
            <div class="mt-3">
              <a href="javascript: void(0);" class="read-btn">MORE <i class="mdi mdi-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END NEWS -->
