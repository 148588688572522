import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-product-list-by-application',
  templateUrl: './product-list-by-application.component.html',
  styleUrls: ['./product-list-by-application.component.css']
})
export class ProductListByApplicationComponent {
  @ViewChild('acc') acc: NgbAccordion;
  categories = [];
  subTitle = '';

  constructor(private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    private spinner: NgxSpinnerService) {
    this.scrollToService.scrollTo({ target: 'home' });
    this.route.queryParams.subscribe((params) => {
      this.readProductList(params.category, params.tag);
    })
  }

  toDetail(product) {
    this.router.navigateByUrl('/product/detail?id=' + product.id);
  }

  readProductList(category, tag) {
    this.spinner.show();
    this.http.post('/product/list-by-tag', { tag: tag })
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
          this.acc.expandAll();
        }, 618);
      }))
      .subscribe((res: any) => {
        this.categories = res.categories.map((category) => {
          if (category.child_nodes.length === 0) {
            category.products = res.products.filter((product) => {
              return product.category_id == category.id;
            });
          } else {
            category.products = [];
            category.child_nodes = category.child_nodes.map((childNode) => {
              childNode.products = res.products.filter((product) => {
                return product.category_id == childNode.id;
              });
              return childNode;
            }).filter((childNode) => {
              return childNode.products.length > 0;
            });
          }
          return category;
        });
        this.subTitle = res.subTitle;
      });
  }

  imgLoaded($event, product) {
    product.loaded = true;
  }
}
