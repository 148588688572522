import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent {
  public productId: any;
  public product: any;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private scrollToService: ScrollToService,
    private spinner: NgxSpinnerService) {
    this.scrollToService.scrollTo({ target: 'home' });
    this.route.queryParams.subscribe((params) => {
      this.productId = params.id;
      this.readProductDetail();
    })
  }

  readProductDetail() {
    this.spinner.show();
    this.http.post('/product/detail', { product_id: this.productId })
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 618);
      }))
      .subscribe((res: any) => {
        this.product = res.product;
      });
  }

  back() {
    window.history.back();
  }

}
