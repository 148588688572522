import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PagesRoutingModule } from './pages-routing.module';
import { IndexComponent } from './index/index.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { CompanyIntroductionComponent } from './company/company-introduction/company-introduction.component';
import { ProductListByCategoryComponent } from './product/product-list-by-category/product-list-by-category.component';
import { ProductListByApplicationComponent } from './product/product-list-by-application/product-list-by-application.component';
import { NgxAmapModule } from 'ngx-amap';
import { NgxPictureModule } from 'ngx-picture';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ApplicationComponent } from './application/application.component';
import { SuccessComponent } from './success/success.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    IndexComponent,
    NewsListComponent,
    NewsDetailComponent,
    ProductListComponent,
    ProductDetailComponent,
    CompanyIntroductionComponent,
    ProductListByCategoryComponent,
    ProductListByApplicationComponent,
    ContactUsComponent,
    ApplicationComponent,
    SuccessComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPictureModule,
    NguCarouselModule,
    ReactiveFormsModule,
    NgxYoutubePlayerModule.forRoot(),
    ScrollToModule.forRoot(),
    NgxAmapModule.forRoot({
      apiKey: 'c9ca9e517c85cdbffc6dd51531491048'
    })
  ]
})
export class PagesModule {
}
