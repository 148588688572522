import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-application-field',
  templateUrl: './application-field.component.html',
  styleUrls: ['./application-field.component.css']
})
export class ApplicationFieldComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toList(tag) {
    this.router.navigateByUrl('/product/list-by-application?tag=' + tag);
  }
}
