import { Component, OnInit } from '@angular/core';
import emailjs from 'emailjs-com';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  emailPattern: string = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      phone: ['', Validators.required],
      origin: ['友邦'],
    });
  }

  submitForm() {
    if (this.form.valid) {
      const template_params = {
        reply_to: 'saint@solutioneden.com',
        ...this.form.value
      };

      const user_id = 'user_jCTIgKxYBwlwYGfd2ibN0';
      const service_id = 'solutioneden';
      const template_id = 'solutioneden_quotation';
      this.spinner.show();
      emailjs.send(service_id, template_id, template_params, user_id).then(
        () => {
          this.form.reset();
          this.toast.success('留言成功，我们会尽快跟您联系！');
        }, (error) => {
          console.log(error);
          this.toast.error('留言失败，请稍后重试！');
        }
      ).finally(() => {
        this.spinner.hide();
      });
    } else {
      this.toast.error('请正确填写信息！');
    }
  }

}
