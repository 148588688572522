import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toList(tag) {
    this.router.navigateByUrl('/product/list-by-application?tag=' + tag);
  }

}
