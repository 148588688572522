<!-- STRAT NAVBAR -->
<app-header [currentSection]="'home'"></app-header>

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h4 class="home-small-title">
            A comprehensive enterprise dealing with world water treatment
          </h4>
          <h1 class="home-title">World Water Treatment</h1>
          <p class="padding-t-15 home-desc mx-auto">
            YB Water Treatment Ltd is one of the leading water treatment companies located in central China. Since
            1980s, we’ve been delivering innovative and sustainable solutions for several applications and processes of
            water treatment, wastewater reuse,industrial cirlulation water and sewage treatments.
          </p>
          <p class="home-desc mx-auto">
            Our wide range of technologies for water treatment products are design and serving for numerous industries
            in differeant areas.
          </p>
          <p class="home-desc mx-auto">
            We fomulate,supply and manufacture various water treatment chemicals with our own technologies under our own
            lable with high quality standerds.
          </p>
          <p class="home-desc mx-auto">
            YB Water will guarantee you a cost-efficient solution with our best products for your individual water
            quality requirements.
          </p>
          <p class="play-shadow margin-l-r-auto mb-4"></p>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png');"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png');"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png');"></div>
      </div>
    </div>
  </section>
  <!--END HOME-->

  <app-product></app-product>
  <app-corporate-culture></app-corporate-culture>
  <app-application-field></app-application-field>
  <app-successful-case *ngIf="false"></app-successful-case>
  <app-news [items]="news" *ngIf="false"></app-news>
  <app-contact *ngIf="false"></app-contact>
  <section class="section bg-dark-blue pb-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="mx-auto">
            <img style="max-width: 100%" src="assets/new-images/bottom.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <ngx-amap style="height: 450px;" [center]="[112.960396, 34.706]" [zoom]="16">
    <amap-marker [position]="[112.960396, 34.706]">
      <amap-info-window [isOpen]="true" [position]="[112.960396, 34.706]" [offset]="offset">
        WORLD WATER TREATMENT
      </amap-info-window>
    </amap-marker>
  </ngx-amap>
  <ngx-spinner></ngx-spinner>
  <app-footer></app-footer>
</div>