import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactComponent} from './contact/contact.component';
import {FooterComponent} from './footer/footer.component';
import {ScrollspyDirective} from './scrollspy.directive';
import {ProductComponent} from './product/product.component';
import {CorporateCultureComponent} from './corporate-culture/corporate-culture.component';
import {ApplicationFieldComponent} from './application-field/application-field.component';
import {SuccessfulCaseComponent} from './successful-case/successful-case.component';
import {NewsComponent} from './news/news.component';
import {NguCarouselModule} from '@ngu/carousel';
import { HeaderComponent } from './header/header.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    FooterComponent,
    ProductComponent,
    ContactComponent,
    ScrollspyDirective,
    CorporateCultureComponent,
    ApplicationFieldComponent,
    SuccessfulCaseComponent,
    NewsComponent,
    HeaderComponent,
    SafeHtmlPipe
  ]
  ,
  imports: [
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NguCarouselModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot()
  ],
  // tslint:disable-next-line: max-line-length
  exports: [
    HeaderComponent,
    FooterComponent,
    ProductComponent,
    ContactComponent,
    ScrollspyDirective,
    CorporateCultureComponent,
    ApplicationFieldComponent,
    SuccessfulCaseComponent,
    NewsComponent,
    SafeHtmlPipe
  ]
})
export class SharedModule {
}
