<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
     id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img src="/assets/images/logo.png" style="max-width: 75px"/>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a href="javascript: void(0);" (click)="jump('/')" class="nav-link">HOME</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'corporate-culture'}">
          <a href="javascript: void(0);" (click)="jump('/company/introduction')" class="nav-link">CULTURE</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'product'}">
          <a href="javascript: void(0);" (click)="jump('/product/list-by-category')" class="nav-link">PRODUCTS</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'news'}">
          <a href="javascript: void(0);" (click)="jump('/news/list')" class="nav-link">NEWS</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'contact-us'}">
          <a href="javascript: void(0);" (click)="jump('/contact-us')" class="nav-link">CONTACT US</a>
        </li>
        <li class="nav-item">
          <a href="https://www.ybwater.cn/" target="_self" class="nav-link">CN</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
