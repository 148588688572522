<app-header [currentSection]="'news'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" *ngIf="news">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h1 class="home-title">{{news.title}}</h1>
          <h4>{{news.created_at | date: 'yyyy年MM月dd日 HH:mm'}}</h4>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
      </div>
    </div>
  </section>
</div>

<section class="section bg-light"  *ngIf="news" style="min-height: 500px;padding-bottom: 30px;">
  <div class="container">
    <div>
      <div class="ct2-mn ct2-mn-left">
        <div class="content-body" [innerHTML]="news.description | safeHtml">
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
