<app-header [currentSection]="'corporate-culture'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center">
          <h1 class="home-title">WORLD WATER TREATMENT</h1>
          <h4>A comprehensive enterprise dealing with world water treatment.</h4>
          <p class="play-shadow margin-l-r-auto mb-4">
            <a (click)="openWindowCustomClass(content)" class="play-btn video-play-icon"><i
                class="mdi mdi-play text-center"></i></a>
          </p>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
      </div>
    </div>
  </section>

  <section class="section bg-light" style="min-height: 500px; padding-bottom: 80px; padding-top: 30px">
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div class="client-images my-3 my-md-0">
            <img src="assets/images/clients/1.png" alt="logo-img" class="mx-auto img-fluid d-block" />
          </div>
        </div>
        <div class="col-4">
          <div class="client-images my-3 my-md-0">
            <img src="assets/images/clients/2.png" alt="logo-img" class="mx-auto img-fluid d-block" />
          </div>
        </div>
        <div class="col-4">
          <div class="client-images my-3 my-md-0">
            <img src="assets/images/clients/3.png" alt="logo-img" class="mx-auto img-fluid d-block" />
          </div>
        </div>
      </div>
      <p class="padding-t-15 desc mx-auto mt-5">
        <span style="font-weight: 900">YB Water Treatment Ltd</span>, located in Gongyi, Henan, a famous city in the Central Plains, it was founded in the 1980s and is a national high-tech and ISO9001、ISO4001 certified enterprise. It is one of the earliest domestic manufacturers of urban water supply and drainage, industrial circulating water and sewage treatment agents. After more than 30 years of development, the design, production, sales and service of water treatment chemicals and water treatment fillers are integrated. Moreover, it extends to installation, cleaning river and lake renovation, operation contracting and other engineering fields. Customers cover various fields such as sewage treatment, steel mills, power plants, oil refineries, papermaking, textiles, printing and dyeing, and also mineral processing.
      </p>
      <p class="padding-t-15 desc mx-auto">Since the establishment of YB Water Treatment Ltd, it has been conducting close technical cooperation with Beijing University of Chemical Technology, Henan University and other institutions. The company has established a " Water Treatment Chemicals R&D Center" to conduct technical research and new product development to fully satisfy customers’ requirements.
      </p>
      <p class="padding-t-15 desc mx-auto">In 2003, the company was reorganized and restructured, officially using the "YB" logo, and completed the registration of the "YB" trademark. The company takes "integrity", "diligence", "dedication", "inspirational" and "innovation" as its business philosophy, respecting customers, understanding customers, and serving customers. In the continuous development and exploration, YB Water Treatment Ltd is willing to work together with all customers all around the world.
      </p>
    </div>
  </section>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe style="width: 100%; min-height: 500px" frameborder="0"
      src="https://v.qq.com/txp/iframe/player.html?vid=n3017sjf6x5" allowFullScreen="true">
    </iframe>
  </div>
</ng-template>
<app-footer></app-footer>
