<!--START OLD CORPORATE CULTURE -->
<section class="section bg-light" id="corporate-culture" *ngIf="false">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">CULTURE</h1>
        <div class="section-title-border margin-t-20"></div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/1.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/2.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/3.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col-lg-6 order-2 order-lg-1 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/company.jpg?timestamp=20071401" style="max-width: 100%;" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-lg-6 order-1 order-lg-2 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img style="max-width: 100%;" src="assets/images/kyzx.jpg" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
<!--END OLD CORPORATE CULTURE-->

<!--START CORPORATE CULTURE -->
<section class="section bg-light" id="corporate-culture">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Corporate Culture</h1>
        <div class="section-title-border margin-t-20"></div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/1.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/2.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
      <div class="col-4">
        <div class="client-images my-3 my-md-0">
          <img src="assets/images/clients/3.png" alt="logo-img" class="mx-auto img-fluid d-block">
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col-lg-6 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/company_1.jpg?timestamp=22062001" style="max-width: 100%;" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-lg-6 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img style="max-width: 100%;" src="assets/images/company_2.jpg?timestamp=22062001" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col-lg-6 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/new-images/about-us-1.jpg?timestamp=20220713" style="max-width: 100%;" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-lg-6 cursor-box" (click)="turnDetail()">
        <div class="features-img mx-auto mr-lg-0">
          <img style="max-width: 100%;" src="assets/new-images/about-us-2.jpg?timestamp=20220713" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
<!--END CORPORATE CULTURE-->

