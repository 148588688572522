import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit, AfterViewInit, AfterContentChecked {

  cases = [
    {
      image: 'assets/images/successful-case/1-1.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-2.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-3.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-4.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-5.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-6.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-7.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-8.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-9.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-10.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-11.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-12.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-13.jpg',
      title: ''
    },
    {
      image: 'assets/images/successful-case/1-14.jpg',
      title: ''
    }
  ];

  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    speed: 618 * 2,
    point: {
      visible: false
    },
    touch: true,
    loop: true,
    interval: { timing: 2500, initialDelay: 2000 },
    animation: 'lazy'
  };

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    const cols = document.getElementsByClassName('tile');
    for (let i = 0; i < cols.length; i++) {
      cols[i].classList.remove('tile');
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }


}
