import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent {
  public newsId: any;
  public news: any;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private spinner: NgxSpinnerService) {
    this.route.queryParams.subscribe((params) => {
      this.newsId = params.id;
      this.readNewsDetail();
    })
  }

  readNewsDetail() {
    this.spinner.show();
    this.http.post('/news/detail', { news_id: this.newsId })
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 618);
      }))
      .subscribe((res: any) => {
        this.news = res.news;
      });
  }

}
