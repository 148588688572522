import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url[0] !== '.') {
            const apiUrl = environment.apiUrl;
            req = req.clone({
                url: apiUrl + req.url,
                setHeaders: this.readHttpHeader(),
            });
        }
        const response = next.handle(req);
        return response.pipe(
            catchError(errors => this.errorHandler(errors)),
            tap((res) => {
                if (res['body'] && res['body']['data']) {
                    return res['body'] = res['body']['data'];
                }
                return res;
            })
        );
    }

    public readHttpHeader() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        return headers;
    }

    // Customize the default error handler here if needed
    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
        const errors = { ...response.error };
        // if (!errors.hasOwnProperty('message')) {
        //     this._common.showToast({ message: 'http_error', color: 'danger' }).then();
        // } else {
        //     if (errors.hasOwnProperty('errors') && Object.keys(errors.errors).length > 0) {
        //         const message = [];
        //         Object.keys(errors.errors).forEach((key) => {
        //             for (const item of response.error.errors[key]) {
        //                 message.push(item);
        //             }
        //         });
        //         this._common.showToast({ message: message.join('<br>'), color: 'danger' }).then();
        //     } else {
        //         this._common.showToast({ message: errors.message, color: 'danger' }).then();
        //     }
        // }

        throw response;
    }
}
