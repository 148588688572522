<!--START FOOTER ALTER-->
<div class="footer-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-left pull-none">
          <p class="copy-rights text-muted mb-3 mb-sm-0">
            2018 - {{ year }} © World Water Treatment Limited
          </p>
        </div>
        <div class="float-right pull-none">
          <ng-template #staff1>
            <img src="/assets/images/wechat_1.png" style="padding: 5px; width: 100%;" />
          </ng-template>
          <ng-template #staff2>
            <img src="/assets/images/wechat_2.jpg" style="padding: 5px; width: 100%;" />
          </ng-template>
          <ul class="list-inline social m-0">
            <li class="list-inline-item">
              <a href="javascript: void(0);" [ngbTooltip]="staff1" placement="top" tiggers="click:focus hover"
                class="social-icon"><i class="mdi mdi-wechat"></i></a>
              <span class="social-icon-text">Sales</span>
            </li>
            <li class="list-inline-item">
              <a href="javascript: void(0);" [ngbTooltip]="staff2" placement="top" tiggers="click:focus hover"
                class="social-icon"><i class="mdi mdi-wechat"></i></a>
              <span class="social-icon-text">Support</span>
            </li>
            <!--
              <li class="list-inline-item"><a href="javascript: void(0);" class="social-icon"><i class="mdi mdi-sina-weibo"></i></a></li>
              <li class="list-inline-item"><a href="javascript: void(0);" class="social-icon"><i class="mdi mdi-qqchat"></i></a></li>
            -->
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
<!--START FOOTER ALTER-->
