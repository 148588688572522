<!-- CONTACT FORM START-->
<section class="section bg-light" id="contact" style="padding-bottom: 20px">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">CONTACT US</h1>
        <div class="section-title-border margin-t-20"></div>
        <p class="section-subtitle text-muted text-center font-secondary padding-t-30">
          We insist on providing customers with cost-effective products and adhering to the business philosophy of
          "integrity-based, caring service" is our constant pursuit.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="mt-4 pt-4">
          <p class="mt-4">
            <span class="h5">Office line:</span><span class="text-muted d-block mt-2">0371-64109456</span>
          </p>
          <p class="mt-4">
            <span class="h5">Sales line:</span><span class="text-muted d-block mt-2">0371-64109111</span>
            <span class="text-muted d-block mt-2">0371-64109666</span>
          </p>
          <p class="mt-4">
            <span class="h5">Email:</span><span class="text-muted d-block mt-2">info@worldwatertreatment.com</span>
          </p>
          <p class="mt-4">
            <span class="h5">Address：</span><span class="text-muted d-block mt-2">No. 10, Yongyi Road, Yong'an Street
              Office, Gongyi City, Zhengzhou City, Henan Province</span>
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="custom-form mt-4 pt-4">
          <div id="message"></div>
          <form method="post" [formGroup]="form">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <input name="name" type="text" class="form-control" formControlName="name" placeholder="Name *" />
                  <small class="text-danger" *ngIf="
                      form.controls['name'].hasError('required') &&
                      form.controls['name'].touched
                    ">
                    Your name
                  </small>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <input name="phone" type="text" class="form-control" formControlName="phone"
                    placeholder="Contact number *" />
                  <small class="text-danger" *ngIf="
                      form.controls['phone'].hasError('required') &&
                      form.controls['phone'].touched
                    ">
                    Your contact number
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <input name="email" formControlName="email" type="email" class="form-control" placeholder="Email *" />
                  <small class="text-danger" *ngIf="
                      form.controls['email'].errors &&
                      form.controls['email'].touched
                    ">
                    Your email
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <textarea name="message" formControlName="message" rows="4" class="form-control"
                    placeholder="Message *"></textarea>
                  <small class="text-danger" *ngIf="
                      form.controls['message'].hasError('required') &&
                      form.controls['message'].touched
                    ">
                    Your message
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-right">
                <button type="button" class="submitBnt btn btn-primary" (click)="submitForm()">
                  SEND
                  <i class="mdi mdi-email"></i>
                </button>
                <a href="http://wpa.qq.com/msgrd?v=3&uin=20612730&site=qq&menu=yes" class="btn btn-success"
                  style="margin-left: 30px" target="_blank">Online Chat<i class="mdi mdi-arrow-right"></i></a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- CONTACT FORM END-->
