import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corporate-culture',
  templateUrl: './corporate-culture.component.html',
  styleUrls: ['./corporate-culture.component.css']
})
export class CorporateCultureComponent {

  constructor(private router: Router) { }

  turnDetail() {
    this.router.navigateByUrl('/company/introduction');
  }

}
