<app-header [currentSection]="'successful-case'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center" style="z-index: 20">
          <h1 class="home-title">CASE STUDY</h1>
          <h4>
            The company is the supplier of many well-known domestic and foreign enterprises such as Shougang Jingtang
            Iron and Steel Joint Liability Co., Ltd. and Qian'an Iron and Steel Joint Liability Co., Ltd.
          </h4>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
      </div>
    </div>
  </section>
  <section class="section bg-light" style="min-height: 500px; padding-bottom: 80px; padding-top: 30px">
    <div class="container">

      <ngu-carousel [inputs]="carouselTileConfig" [dataSource]="cases">
        <ngu-tile *nguCarouselDef="let item; index as i;" class="no-border">
          <div class="team-box text-center">
            <div class="team-wrapper">
              <div class="team-member">
                <img alt="" src="{{item.image}}" class="img-fluid rounded">
              </div>
            </div>
            <h4 class="team-name">{{item.title}}</h4>
          </div>
        </ngu-tile>
      </ngu-carousel>
    </div>
  </section>
</div>
<ngx-spinner></ngx-spinner>
<app-footer></app-footer>
