<!--START SUCCESSFUL CASE-->
<section class="section bg-light" id="successful-case">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">CASE STUDY</h1>
        <div class="section-title-border margin-t-20"></div>
        <p class="section-subtitle text-muted text-center font-secondary padding-t-30">
          The company is a qualified water treatment product supplier for many well-know companies. Supplies
          high-quality products and considerate services. The service and products had been unanimously praised by
          users.
        </p>
      </div>
    </div>
    <ngu-carousel [inputs]="carouselTileConfig" [dataSource]="cases">
      <ngu-tile *nguCarouselDef="let item; index as i;" class="no-border">
        <div class="team-box text-center">
          <div class="team-wrapper">
            <div class="team-member">
              <img alt="" src="{{item.image}}" class="img-fluid rounded">
            </div>
          </div>
          <h4 class="team-name">{{item.title}}</h4>
        </div>
      </ngu-tile>
    </ngu-carousel>
  </div>
</section>
<!--END SUCCESSFUL CASE-->
