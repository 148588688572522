import {Component, OnInit, ViewEncapsulation, ViewChild, NgZone, OnDestroy} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

declare const qq: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {

  currentSection = 'home';
  news = [];
  offset = {
    x: 0,
    y: -30,
  };

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private zone: NgZone,
    private spinner: NgxSpinnerService) {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      navbar.style.backgroundColor = '#272a33';
      navbar.style.padding = '10px';
    } else {
      navbar.style.backgroundColor = '';
      navbar.style.padding = '20px';
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  ngOnInit() {
    this.spinner.show();
    this.http.post('/home/news/list', {})
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 618);
      }))
      .subscribe((data: any) => {
        this.news =  data.news;
      });
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {windowClass: 'dark-modal', size: 'lg'});
  }

}
