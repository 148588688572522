<app-header [currentSection]="'product'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section
    class="section bg-home home-half"
    id="home"
    data-image-src="images/bg-home.jpg"
  >
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 text-white text-center"
          style="z-index: 20"
        >
          <h1 style="z-index: 20" *ngIf="current_category">
            {{ current_category.display }}
          </h1>
          <h4 class="home-title">PRODUCTS</h4>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let category_item of categories">
          <div
            class="col-lg-4 col-6 text-white text-center mt-3"
          >
            <a
              (click)="toList(category_item)"
              class="blog-title text-center mt-3 cursor-box add-cursor"
              style="color: #ffffff !important"
            >
              {{ category_item.display }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div
          class="wave wave-one"
          style="background-image: url('assets/images/wave-shape/wave1.png')"
        ></div>
      </div>
      <div class="waves-shape shape-two">
        <div
          class="wave wave-two"
          style="background-image: url('assets/images/wave-shape/wave2.png')"
        ></div>
      </div>
      <div class="waves-shape shape-three">
        <div
          class="wave wave-three"
          style="background-image: url('assets/images/wave-shape/wave3.png')"
        ></div>
      </div>
    </div>
  </section>
</div>

<section
  class="section bg-light"
  style="min-height: 500px; padding-bottom: 30px"
>
  <div class="container">
    <div class="row" *ngIf="!category_id">
      <ng-container *ngFor="let category_item of categories">
        <div class="col-lg-4 col-md-6 col-6">
          <div
            class="blog-box margin-t-30 text-center add-cursor"
            (click)="toList(category_item)"
          >
            <img
              [src]="category_item.thumb"
              class="img-fluid rounded"
              style="max-width: 100%"
            />
            <div>
              <h4 class="mt-3">
                <a
                  (click)="toList(category_item)"
                  class="blog-title text-center"
                  style="color: #212529 !important"
                >
                  {{ category_item.display }}
                </a>
              </h4>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="category && childrenCategories.length === 0">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-6" *ngFor="let product of products">
          <div
            class="blog-box margin-t-30 add-cursor"
            style="text-align: center"
            (click)="toDetail(product)"
          >
            <div
              style="width: 100%; text-align: center"
              [ngClass]="!product.loaded ? 'img-placeholder' : ''"
            >
              <ngx-picture
                src="{{ product.thumb }}"
                class="img-fluid rounded"
                [lazyLoad]="true"
                (loaded)="imgLoaded($event, product)"
              ></ngx-picture>
            </div>
            <div>
              <h4 class="mt-3">
                <a href="javascript: void(0);" class="blog-title">
                  {{ product.name }}
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ngb-tabset
      style="width: 100%"
      *ngIf="category && childrenCategories.length > 0"
    >
      <ng-container *ngFor="let childrenCategory of childrenCategories">
        <ngb-tab>
          <ng-template ngbTabTitle>
            {{ childrenCategory.name }}
          </ng-template>
          <ng-template ngbTabContent>
            <div class="row">
              <div
                class="col-lg-3 col-md-6"
                *ngFor="let product of childrenCategory.products"
              >
                <div
                  class="blog-box margin-t-30"
                  style="text-align: center"
                  (click)="toDetail(product)"
                >
                  <div
                    style="width: 100%; text-align: center"
                    [ngClass]="!product.loaded ? 'img-placeholder' : ''"
                  >
                    <ngx-picture
                      src="{{ product.thumb }}"
                      class="img-fluid rounded"
                      [lazyLoad]="true"
                      (loaded)="imgLoaded($event, product)"
                    ></ngx-picture>
                  </div>
                  <div>
                    <h4 class="mt-3">
                      <a href="javascript: void(0);" class="blog-title">
                        {{ product.name }}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ng-container>
    </ngb-tabset>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<app-footer></app-footer>
