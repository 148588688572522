import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { NewsListComponent } from "./news/news-list/news-list.component";
import { NewsDetailComponent } from "./news/news-detail/news-detail.component";
import { ProductListComponent } from "./product/product-list/product-list.component";
import { ProductDetailComponent } from "./product/product-detail/product-detail.component";
import { CompanyIntroductionComponent } from './company/company-introduction/company-introduction.component';
import { ProductListByCategoryComponent } from './product/product-list-by-category/product-list-by-category.component';
import { ProductListByApplicationComponent } from './product/product-list-by-application/product-list-by-application.component';
import { SuccessComponent } from './success/success.component';
import { ApplicationComponent } from './application/application.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'index',
    component: IndexComponent
  },
  {
    path: 'news',
    children: [
      {
        path: 'list',
        component: NewsListComponent
      },
      {
        path: 'detail',
        component: NewsDetailComponent
      }
    ]
  },
  {
    path: 'product',
    children: [
      {
        path: 'list',
        component: ProductListComponent,
        data: {keep: true}
      },
      {
        path: 'list-by-category',
        component: ProductListByCategoryComponent,
        data: { keep: true }
      },
      {
        path: 'list-by-application',
        component: ProductListByApplicationComponent,
        data: { keep: true }
      },
      {
        path: 'detail',
        component: ProductDetailComponent
      }
    ]
  },
  {
    path: 'company',
    children: [
      {
        path: 'introduction',
        component: CompanyIntroductionComponent
      }
    ]
  },
  {
    path: 'success',
    component: SuccessComponent
  },
  {
    path: 'application',
    component: ApplicationComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
