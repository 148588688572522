<app-header [currentSection]="'application-field'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']">
  <section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-white text-center" style="z-index: 20">
          <h1 class="home-title">INDUSTRIES</h1>
          <h4>
            Widely being used in tap water, petroleum, chemical, light industry, metallurgy, electric power, papermaking
            and other industries.
          </h4>
        </div>
      </div>
    </div>
    <div class="wave-effect wave-anim">
      <div class="waves-shape shape-one">
        <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
      </div>
      <div class="waves-shape shape-two">
        <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
      </div>
      <div class="waves-shape shape-three">
        <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
      </div>
    </div>
  </section>
</div>

<section class="section bg-light" style="min-height: 500px; padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1">
        <div class="features-box mt-5 mt-lg-0">
          <h3>STEEL INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">
              Chemicals: Corrosion inhibitors,Bactericide Algaecide,Slime stripper,Antifoams,Scale inhibitors,Reverse
              osmosis fungicide,Reducing agent,Modifier,Reverse osmosis cleaning agent,Polyaluminum
              chloride,Polyacrylamide.
            </li>
            <li class="">
              Filtration Media: Anthracite, Quartz sand, Cobblestone, Garnet, Emery.
            </li>
            <li class="">
              Stuffing Media: inclined tube, fiber ball, filter cap, 3D elastic material, Pall ring, combined ... etc.
            </li>
          </ul>
          <button (click)="toList('钢铁企业')" class="btn btn-custom">PRODUCTS <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
      <div class="col-lg-7 order-1 order-lg-2">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/gtc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section" style="padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1">
        <div class="features-img mr-lg-0">
          <img src="assets/images/dc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-5 order-1 order-lg-2">
        <div class="features-box mt-5 mt-lg-0">
          <h3>ELECTRICITY INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">
              Chemicals: Corrosion inhibitors,Bactericide Algaecide,Slime stripper,Antifoams,Scale inhibitors,Reverse
              osmosis fungicide,Reducing agent,Reverse osmosis cleaning agent,Polyacrylamide.
            </li>
            <li class="">
              Filtration Media: Anthracite, Quartz sand, Cobblestone, Garnet, Emery.
            </li>
            <li class="">
              Stuffing Media: inclined tube, fiber ball, filter cap, 3D elastic material, Pall ring, combined ... etc.
            </li>
          </ul>
          <button (click)="toList('电厂')" class="btn btn-custom">PRODUCTS <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section bg-light" style="padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1">
        <div class="features-box mt-5 mt-lg-0">
          <h3>WATER TREATEMENT INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">
              Chemicals: Flocculant, Ammonia Nitrogen Remover, COD remover, Heavy Metal Capture Agent, Bactericide,
              Defoamer, Glucose, Polyaluminum Chloride, Polyacrylamide.
            </li>
            <li class="">
              Filtration Media: Anthracite, Quartz sand, Cobblestone, Activated carbon.
            </li>
            <li class="">
              Stuffing Media: inclined tube, filter cap, 3D elastic material, Pall ring, combined ... etc.
            </li>
          </ul>
          <button (click)="toList('污水处理厂')" class="btn btn-custom">PRODUCTS <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
      <div class="col-lg-7 order-1 order-lg-2">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/wsclc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section" style="padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1">
        <div class="features-img mr-lg-0">
          <img src="assets/images/hgc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-5 order-1 order-lg-2">
        <div class="features-box mt-5 mt-lg-0">
          <h3>PETROCHEMICAL INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">Chemicals: Corrosion inhibitors,Bactericide Algaecide.</li>
          </ul>
          <button (click)="toList('石油化工')" class="btn btn-custom">直达产品 <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section bg-light" style="padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1">
        <div class="features-box mt-5 mt-lg-0">
          <h3>COAL MINE INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">
              Chemicals: Scale inhibitors, Reverse osmosis fungicide, Reducing agent, Modifier, Polyaluminum chloride,
              Polyacrylamide
            </li>
          </ul>
          <button (click)="toList('煤矿厂')" class="btn btn-custom">PRODUCTS <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
      <div class="col-lg-7 order-1 order-lg-2">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/mkc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section" style="padding-bottom: 80px; padding-top: 30px">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 order-2 order-lg-1">
        <div class="features-img mr-lg-0">
          <img src="assets/images/snc.png" alt="macbook image" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-5 order-1 order-lg-2">
        <div class="features-box mt-5 mt-lg-0">
          <h3>CEMENT PLANT INDUSTRY</h3>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">
              Chemicals: Corrosion inhibitors, Bactericide Algaecide, Polyaluminum chloride, Polyacrylamide
            </li>
          </ul>
          <button (click)="toList('水泥厂')" class="btn btn-custom">PRODUCTS <i class="mdi mdi-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
