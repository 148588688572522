import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent {
  @ViewChild('acc') acc: NgbAccordion;
  categories = [];
  subTitle = '';

  constructor(private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    private spinner: NgxSpinnerService) {
    this.scrollToService.scrollTo({ target: 'home' });
    this.route.queryParams.subscribe((params) => {
      this.readProductList(params.category, params.tag);
    })
  }

  toDetail(product) {
    this.router.navigateByUrl('/product/detail?id=' + product.id);
  }

  readProductList(category, tag) {
    this.spinner.show();
    this.http.post('/product/list', { category: category, tag: tag })
      .pipe(finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
          this.acc.expandAll();
        }, 618);
      }))
      .subscribe((res: any) => {
        this.categories = res.categories;
        this.subTitle = res.subTitle;
      });
  }
}
