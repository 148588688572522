<!--START OLD APPLICATION FIELD-->
<section class="section bg-light" id="application-field" *ngIf="false">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">INDUSTRIES</h1>
        <div class="section-title-border margin-t-20"></div>
        <p class="section-subtitle text-muted text-center padding-t-30 font-secondary">
          Widely being used in tap water, petroleum, chemical, light industry, metallurgy, electric power,
          papermaking and other industries.
        </p>
      </div>
    </div>
    <div class="row margin-t-30">
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('钢铁企业')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/gtc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">STEEL INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('电厂')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/dc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">ELECTRICITY INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('污水处理厂')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/wsclc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">WATER TREATEMENT INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('石油化工')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/hgc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">PETROCHEMICAL INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('煤矿厂')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/mkc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">COAL MINE INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('水泥厂')">
          <div class="media">
            <div class="media-body">
              <img src="assets/images/snc.png" class="img-fluid rounded" style="max-width: 100%;" />
              <h4 class="text-center margin-t-30">CEMENT PLANT INDUSTRY</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--START OLD APPLICATION FIELD-->

<!--START APPLICATION FIELD-->
<section class="section bg-light" id="application-field">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Areas</h1>
        <div class="section-title-border margin-t-20"></div>
        <p
          class="section-subtitle text-muted text-center padding-t-30 font-secondary"
        >
          Our products are widely used in industries such as tap water, petroleum, chemicals, light industry, metallurgy, power, papermaking, and have received unanimous praise and favorable reviews from our users.
        </p>
      </div>
    </div>
    <div class="row margin-t-30">
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('钢铁企业')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/gtc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Manufacturing & Steel Processing</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('电厂')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/dc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Power Generation</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('污水处理厂')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/wsclc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Wastewater Treatment</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('石油化工')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/hgc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Oil & Gas</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('煤矿厂')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/mkc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Mining & Minerals</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 margin-t-20">
        <div class="services-box" (click)="toList('水泥厂')">
          <div class="media">
            <div class="media-body">
              <img
                src="assets/images/snc.png"
                class="img-fluid rounded"
                style="max-width: 100%;"
              />
              <h4 class="text-center margin-t-30">Industrial Process Water</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--START APPLICATION FIELD-->

